<template>
  <div class="all">
    <Nav></Nav>
    <div class="video">
      <div class="first">
        <div class="rd-left">
          <div class="rd-list" v-for="(k, index) in redianList" :key="k.id">
            <video
              :src="k.videoURL"
              :poster="k.videoimgURL"
              controls
              :ref="'video_' + index + '_' + k.id"
              @playing="
                onVideoStatusChange('video_' + index + '_' + k.id, $event)
              "
              loop
              width="876"
              height="523"
            ></video>
            <div
              class="rd-img"
              v-if="posterShow"
              @mouseenter="onNoposter()"
              @click="onGoRedian"
              :style="{
                backgroundImage: `url(` + k.videoimgURL + `)`
              }"
            ></div>
          </div>
        </div>
        <div class="rd-right">
          <div class="gd-title">
            <span @click="onGogundong">-- 滚动 --</span>
          </div>
          <div class="gd-list">
            <div class="gd-con" v-for="(k, index) in gundongList" :key="k.id">
              <video
                :src="k.videoURL"
                :poster="k.videoimgURL"
                :ref="'video_' + index + '_' + k.id"
                @playing="
                  onVideoStatusChange('video_' + index + '_' + k.id, $event)
                "
                controls
                loop
                width="230"
                height="135"
              ></video>
              <div
                class="video-poster"
                v-if="k.posterShow"
                @mouseenter="onNoPoster(1, index)"
                :style="{ backgroundImage: `url(` + k.videoimgURL + `)` }"
              ></div>
              <div class="gd-text" @click="onGodetail(k.postId)">
                <div class="time">{{ k.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="video-list">
        <!-- 推荐 -->
        <div class="tuijian">
          <div class="tuijian-title">
            <div class="tj-t el-icon-video-camera">
              <span>推荐</span>
            </div>
            <div class="tj-l" @click="onMore('tuijian')">更多>></div>
          </div>
          <div class="tuijian-con">
            <div class="tj-list" v-for="(k, index) in tuijianList" :key="k.id">
              <video
                :src="k.videoURL"
                :poster="k.videoimgURL"
                :ref="'video_' + index + '_' + k.id"
                @playing="
                  onVideoStatusChange('video_' + index + '_' + k.id, $event)
                "
                controls
                loop
                width="100%"
                height="100%"
              ></video>
              <div
                class="video-poster"
                v-if="k.posterShow"
                @mouseenter="onNoPoster(2, index)"
                :style="{ backgroundImage: `url(` + k.videoimgURL + `)` }"
              ></div>
              <div class="list-text" @click="onGodetail(k.postId)">
                <div class="time">{{ k.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 赛事saishiList -->
        <div class="saishi">
          <div class="saishi-title">
            <div class="tj-t el-icon-video-camera">
              <span>赛事</span>
            </div>
            <div class="tj-l" @click="onMore('saishi')">更多>></div>
          </div>
          <div class="saishi-con">
            <div class="tj-list" v-for="(k, index) in saishiList" :key="k.id">
              <div class="list-top">
                <video
                  :src="k.videoURL"
                  :poster="k.videoimgURL"
                  :ref="'video_' + index + '_' + k.id"
                  @playing="
                    onVideoStatusChange('video_' + index + '_' + k.id, $event)
                  "
                  controls
                  loop
                  width="100%"
                  height="100%"
                ></video>
                <div
                  class="video-poster"
                  v-if="k.posterShow"
                  @mouseenter="onNoPoster(3, index)"
                  :style="{ backgroundImage: `url(` + k.videoimgURL + `)` }"
                ></div>
                <div class="list-text" @click="onGodetail(k.postId)">
                  <div class="time">{{ k.title }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 经济 -->
        <div class="jingji">
          <div class="jingji-title">
            <div class="tj-t el-icon-video-camera">
              <span>经济</span>
            </div>
            <div class="tj-l" @click="onMore('jingji')">更多>></div>
          </div>
          <div class="jingji-con">
            <div class="tj-list" v-for="(k, index) in jingjiList" :key="k.id">
              <div class="list-top">
                <video
                  :src="k.videoURL"
                  :poster="k.videoimgURL"
                  :ref="'video_' + index + '_' + k.id"
                  @playing="
                    onVideoStatusChange('video_' + index + '_' + k.id, $event)
                  "
                  controls
                  loop
                  width="100%"
                  height="100%"
                ></video>
                <div
                  class="video-poster"
                  v-if="k.posterShow"
                  @mouseenter="onNoPoster(4, index)"
                  :style="{ backgroundImage: `url(` + k.videoimgURL + `)` }"
                ></div>
              </div>
              <div class="list-text" @click="onGodetail(k.postId)">
                <div class="time">{{ k.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 社会 -->
        <div class="shehui">
          <div class="shehui-title">
            <div class="tj-t el-icon-video-camera">
              <span>社会</span>
            </div>
            <div class="tj-l" @click="onMore('shehui')">更多>></div>
          </div>
          <div class="shehui-con">
            <div class="tj-list" v-for="(k, index) in shehuiList" :key="k.id">
              <div class="list-top">
                <video
                  :src="k.videoURL"
                  :poster="k.videoimgURL"
                  :ref="'video_' + index + '_' + k.id"
                  @playing="
                    onVideoStatusChange('video_' + index + '_' + k.id, $event)
                  "
                  controls
                  loop
                  width="100%"
                  height="100%"
                ></video>
                <div
                  class="video-poster"
                  v-if="k.posterShow"
                  @mouseenter="onNoPoster(5, index)"
                  :style="{ backgroundImage: `url(` + k.videoimgURL + `)` }"
                ></div>
              </div>
              <div class="list-text" @click="onGodetail(k.postId)">
                <div class="time">{{ k.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 民生 -->
        <div class="minsheng">
          <div class="minsheng-title">
            <div class="tj-t el-icon-video-camera">
              <span>民生</span>
            </div>
            <div class="tj-l" @click="onMore('minsheng')">更多>></div>
          </div>
          <div class="minsheng-con">
            <div class="tj-list" v-for="(k, index) in minshengList" :key="k.id">
              <div class="list-top">
                <video
                  :src="k.videoURL"
                  :poster="k.videoimgURL"
                  :ref="'video_' + index + '_' + k.id"
                  @playing="
                    onVideoStatusChange('video_' + index + '_' + k.id, $event)
                  "
                  controls
                  loop
                  width="100%"
                  height="100%"
                ></video>
                <div
                  class="video-poster"
                  v-if="k.posterShow"
                  @mouseenter="onNoPoster(6, index)"
                  :style="{ backgroundImage: `url(` + k.videoimgURL + `)` }"
                ></div>
              </div>
              <div class="list-text" @click="onGodetail(k.postId)">
                <div class="time">{{ k.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 环境 -->
        <div class="huangjing">
          <div class="huangjing-title">
            <div class="tj-t el-icon-video-camera">
              <span>环境</span>
            </div>
            <div class="tj-l" @click="onMore('huanjing')">更多>></div>
          </div>
          <div class="huangjing-con">
            <div class="tj-list" v-for="(k, index) in huanjingList" :key="k.id">
              <div class="list-top">
                <video
                  :src="k.videoURL"
                  :poster="k.videoimgURL"
                  :ref="'video_' + index + '_' + k.id"
                  @playing="
                    onVideoStatusChange('video_' + index + '_' + k.id, $event)
                  "
                  controls
                  loop
                  width="100%"
                  height="100%"
                ></video>
                <div
                  class="video-poster"
                  v-if="k.posterShow"
                  @mouseenter="onNoPoster(7, index)"
                  :style="{ backgroundImage: `url(` + k.videoimgURL + `)` }"
                ></div>
              </div>
              <div class="list-text" @click="onGodetail(k.postId)">
                <div class="time">{{ k.title }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 链接 -->
        <div class="link">
          <div class="link-left">
            <div class="name">
              <span class="el-icon-minus"></span>
              友情链接
              <span class="el-icon-minus"></span>
            </div>
            <div class="link-img">
              <div class="link-list">
                <div
                  class="link-detail"
                  v-for="p in linkList"
                  :key="p.id"
                  :style="{ backgroundImage: `url(` + p.imgURL + `)` }"
                >
                  <a :href="p.link" target="_blank"></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import Nav from "@/components/videoNav.vue";
import Bottom from "@/components/bottom.vue";

export default {
  components: {
    Nav,
    Bottom
  },
  data: function() {
    return {
      contents: [], //所有视频的栏目
      redianList: [], // 热点
      gundongList: [], //栏目滚动
      tuijianList: [], // 推荐
      saishiList: [], // 赛事
      jingjiList: [], //经济
      shehuiList: [], // 社会
      minshengList: [], // 民生
      huanjingList: [], // 环境
      linkList: [],
      posterShow: true // 封面默认显示
    };
  },
  created() {
    this.reload();
  },
  methods: {
    reload() {
      var thiz = this;
      RD.index()
        .video()
        .then(data => {
          data.contents.forEach(k => {
            k.list.forEach(i => {
              i.videoimgURL = RD.video(i.postId).poster_URL();
              i.videoURL = RD.video(i.postId).svideo_URL();
              i.posterShow = true;
            });
            if (k.title == "滚动") {
              thiz.gundongList = k.list;
            } else if (k.title == "推荐") {
              thiz.tuijianList = k.list;
            } else if (k.title == "赛事") {
              thiz.saishiList = k.list;
            } else if (k.title == "经济") {
              thiz.jingjiList = k.list;
            } else if (k.title == "社会") {
              thiz.shehuiList = k.list;
            } else if (k.title == "民生") {
              thiz.minshengList = k.list;
            } else if (k.title == "环境") {
              thiz.huanjingList = k.list;
            } else if (k.title == "热点") {
              thiz.redianList = k.list;
            }
          });
          thiz.contents = data.contents;
          RD.index()
            .links()
            .then(data => {
              data.forEach(i => {
                if (i.id) {
                  i.imgURL = RD.exchange(i.id).headPic_URL();
                }
              });
              thiz.linkList = data;
            });
          console.log("videomain", thiz.gundongList);
        });
    },
    // 视频资源中的更多
    onMore(name) {
      console.log("name", name);
      if (name == "tuijian") {
        this.$router.push({
          path: "/videoFixtopic.html",
          query: { id: 43 }
        });
      } else if (name == "saishi") {
        this.$router.push({
          path: "/videoFixtopic.html",
          query: { id: 44 }
        });
      } else if (name == "jingji") {
        this.$router.push({
          path: "/videoFixtopic.html",
          query: { id: 45 }
        });
      } else if (name == "shehui") {
        this.$router.push({
          path: "/videoFixtopic.html",
          query: { id: 56 }
        });
      } else if (name == "minsheng") {
        this.$router.push({
          path: "/videoFixtopic.html",
          query: { id: 57 }
        });
      } else if (name == "huanjing") {
        this.$router.push({
          path: "/videoFixtopic.html",
          query: { id: 58 }
        });
      }
      // this.$message("页面建设中，等稍等");
    },
    // 移入热点封面消失
    onNoposter() {
      console.log("display");
      this.posterShow = false;
    },
    // 移入封面消失
    onNoPoster(value, index) {
      var thiz = this;
      if (value == 1) {
        thiz.gundongList[index].posterShow = false;
      } else if (value == 2) {
        thiz.tuijianList[index].posterShow = false;
      } else if (value == 3) {
        thiz.saishiList[index].posterShow = false;
      } else if (value == 4) {
        thiz.jingjiList[index].posterShow = false;
      } else if (value == 5) {
        thiz.shehuiList[index].posterShow = false;
      } else if (value == 6) {
        thiz.minshengList[index].posterShow = false;
      } else if (value == 7) {
        thiz.huanjingList[index].posterShow = false;
      }
    },
    // 热点
    onGoRedian() {
      this.$router.push({
        path: "/videoFixtopic.html",
        query: { id: 41 }
      });
    },
    // 滚动
    onGogundong() {
      this.$router.push({
        path: "/videoFixtopic.html",
        query: { id: 42 }
      });
    },
    // 查看视频详情
    onGodetail(postId) {
      this.$router.push({
        path: "/videoDetail.html",
        query: { id: postId }
      });
    },
    // 播放状态
    onVideoStatusChange(currentplay, event) {
      var thiz = this;
      console.log("event", event);
      console.log("ref", thiz.$refs);
      for (var key in thiz.$refs) {
        if (key.substr(0, 6) == "video_") {
          if (currentplay == key) {
            // 当前正在播放的
            console.log("currentplay");
          } else {
            // 其他的都暂停
            console.log("sibliingpause", thiz.$refs, thiz.$refs[key]);
            thiz.$refs[key][0].pause();
          }
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
.video {
  width: 100%;
  width: 1200px;
  margin: 0 auto;
  .first {
    height: auto;
    display: flex;
    .rd-left {
      width: 892px;
      height: 542px;
      border: 1px solid #000;
      padding: 5px;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      .rd-list {
        height: 100%;
        .rd-img {
          width: 876px;
          height: 523px;
          background-size: cover;
          cursor: pointer;
          margin: 10px;
          position: absolute;
          top: 0px;
          left: 0px;
        }
        video {
          padding-top: 5px;
        }
      }
    }
    // .rd-left:hover {
    //   .rd-img {
    //     display: none;
    //   }
    // }
    .rd-right {
      width: 256px;
      height: 550px;
      background-color: #eee;
      margin-left: 45px;
      .gd-title {
        height: 36px;
        width: 100%;
        color: #000;
        line-height: 50px;
        font-size: 1.5rem;
        span {
          text-align: center;
          cursor: pointer;
          display: inline-block;
          height: 50px;
        }
      }
      .gd-list {
        width: 100%;
        margin: 0px 13px;
        .gd-con {
          width: 231px;
          height: 168px;
          position: relative;
          .video-poster {
            position: absolute;
            top: 0px;
            left: 0px;
            height: 135px;
            width: 230px;
            background: no-repeat 50% / cover;
          }
          .gd-img {
            height: 80%;
            border: 1px solid #000;
            background: no-repeat 50% / cover;
          }
          .gd-text {
            height: 30px;
            line-height: 30px;
            position: relative;
            cursor: pointer;
            .time {
              position: absolute;
              top: 0px;
              left: 0px;
            }
          }
        }
        // .gd-con:hover {
        //   .video-poster {
        //     display: none;
        //   }
        // }
      }
    }
  }
  .video-list {
    height: auto;
    overflow: hidden;
    margin-top: 50px;
    margin-bottom: 10px;
    .tuijian {
      height: 574px;
      margin-top: 50px;
      .tuijian-title {
        height: 60px;
        display: flex;
        border-bottom: 1px solid #7f7f7f;
        position: relative;
        .tj-t {
          width: 30%;
          color: #000;
          font-size: 2.8rem;
          line-height: 50px;
          text-align: left;
          span {
            font-size: 2rem;
            margin-left: 20px;
          }
        }
        .tj-l {
          height: 25px;
          width: 50px;
          text-align: right;
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 1.2rem;
          cursor: pointer;
        }
      }
      .tuijian-con {
        height: 78%;
        display: flex;
        margin-top: 20px;
        width: 100%;
        position: relative;
        .tj-list {
          height: 100%;
          margin-right: 2%;
          border: 1px solid #ccc;
          position: relative;
          overflow: hidden;
          border-radius: 10px;
          .list-top {
            height: 80%;
            border-bottom: 1px solid #ccc;
          }
          .list-text {
            line-height: 40px;
            cursor: pointer;
            position: absolute;
            bottom: 0px;
            left: 0px;
            color: #fff;
            font-weight: bold;
            width: 100%;
            height: 42px;
            line-height: 42px;
            padding-left: 5px;
            text-align: left;
            background: rgba(0, 0, 0, 0.3);
          }
          .video-poster {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            background: no-repeat 50% / cover;
          }
        }
        // .tj-list:hover {
        //   .video-poster {
        //     display: none;
        //   }
        //   .list-text {
        //     display: none;
        //   }
        // }
      }
      .tuijian-con .tj-list:nth-child(1) {
        width: 434px;
        height: 385px;
        border-radius: 10px;
        position: absolute;
        left: 0px;
        top: opx;
        .list-top {
          height: 90%;
        }
      }
      .tuijian-con .tj-list:nth-child(2),
      .tuijian-con .tj-list:nth-child(3),
      .tuijian-con .tj-list:nth-child(4),
      .tuijian-con .tj-list:nth-child(5) {
        width: 341px;
        height: 184px;
      }
      .tuijian-con .tj-list:nth-child(2) {
        position: absolute;
        left: 464px;
        top: 0px;
      }
      .tuijian-con .tj-list:nth-child(3) {
        position: absolute;
        right: 0px;
        top: 0px;
      }
      .tuijian-con .tj-list:nth-child(4) {
        position: absolute;
        left: 464px;
        top: 202px;
      }
      .tuijian-con .tj-list:nth-child(5) {
        position: absolute;
        right: 0px;
        top: 202px;
      }
    }

    .saishi {
      height: 574px;
      .saishi-title {
        height: 60px;
        display: flex;
        border-bottom: 1px solid #7f7f7f;
        position: relative;
        .tj-t {
          width: 30%;
          color: #000;
          font-size: 2.8rem;
          line-height: 50px;
          text-align: left;
          span {
            font-size: 2rem;
            margin-left: 20px;
          }
        }
        .tj-l {
          height: 25px;
          width: 50px;
          text-align: right;
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 1.2rem;
          cursor: pointer;
        }
      }
      .saishi-con {
        height: 78%;
        display: flex;
        margin-top: 20px;
        width: 100%;
        position: relative;
        .tj-list {
          height: 100%;
          margin-right: 2%;
          border-radius: 10px;
          overflow: hidden;
          .list-top {
            height: 100%;
            border-bottom: 1px solid #ccc;
          }
          .list-text {
            line-height: 40px;
            cursor: pointer;
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 40px;
            line-height: 40px;
            color: #fff;
            background: rgba(0, 0, 0, 0.3);
            text-align: left;
            padding-left: 5px;
            font-weight: bold;
          }
          .video-poster {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            background: no-repeat 50% / cover;
          }
        }
      }
      // .tj-list:hover {
      //   .video-poster {
      //     display: none;
      //   }
      //   .list-text {
      //     display: none;
      //   }
      // }
      .saishi-con .tj-list:nth-child(5) {
        width: 434px;
        height: 385px;
        position: absolute;
        right: 0px;
        top: opx;
      }
      .saishi-con .tj-list:nth-child(2),
      .saishi-con .tj-list:nth-child(3),
      .saishi-con .tj-list:nth-child(4),
      .saishi-con .tj-list:nth-child(1) {
        width: 341px;
        height: 184px;
      }
      .saishi-con .tj-list:nth-child(2) {
        position: absolute;
        left: 371px;
        top: 0px;
      }
      .saishi-con .tj-list:nth-child(3) {
        position: absolute;
        left: 0px;
        top: 202px;
      }
      .saishi-con .tj-list:nth-child(4) {
        position: absolute;
        left: 371px;
        top: 202px;
      }
      .saishi-con .tj-list:nth-child(1) {
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }
    .jingji {
      height: 574px;
      margin-top: 20px;
      .jingji-title {
        height: 60px;
        display: flex;
        border-bottom: 1px solid #7f7f7f;
        position: relative;
        .tj-t {
          width: 30%;
          color: #000;
          font-size: 2.8rem;
          line-height: 50px;
          text-align: left;
          span {
            font-size: 2rem;
            margin-left: 20px;
          }
        }
        .tj-l {
          height: 25px;
          width: 50px;
          text-align: right;
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 1.2rem;
          cursor: pointer;
        }
      }
      .jingji-con {
        height: 78%;
        display: flex;
        margin-top: 20px;
        flex-wrap: wrap;
        width: 101%;
        position: relative;
        .tj-list {
          height: 169px;
          width: 272px;
          margin-right: 28px;
          margin-top: 20px;
          position: relative;
          border-radius: 10px;
          overflow: hidden;
          .list-top {
            height: 100%;
          }
          .list-text {
            line-height: 40px;
            cursor: pointer;
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 40px;
            line-height: 40px;
            color: #fff;
            background: rgba(0, 0, 0, 0.3);
            text-align: left;
            padding-left: 5px;
            font-weight: bold;
          }
          .video-poster {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            background: no-repeat 50% / cover;
          }
        }
        // .tj-list:hover {
        //   .video-poster,
        //   .list-text {
        //     display: none;
        //   }
        // }
      }
    }
    .shehui,
    .minsheng,
    .huangjing {
      height: 574px;
      margin-top: 20px;
      .shehui-title,
      .minsheng-title,
      .huangjing-title {
        height: 60px;
        display: flex;
        border-bottom: 1px solid #7f7f7f;
        position: relative;
        .tj-t {
          width: 30%;
          color: #000;
          font-size: 2.8rem;
          line-height: 50px;
          text-align: left;
          span {
            font-size: 2rem;
            margin-left: 20px;
          }
        }
        .tj-l {
          height: 25px;
          width: 50px;
          text-align: right;
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 1.2rem;
          cursor: pointer;
        }
      }
      .shehui-con,
      .minsheng-con,
      .huangjing-con {
        height: 78%;
        display: flex;
        margin-top: 20px;
        flex-wrap: wrap;
        width: 101%;
        position: relative;
        .tj-list {
          height: 169px;
          width: 272px;
          margin-right: 28px;
          margin-top: 20px;
          position: relative;
          border-radius: 10px;
          overflow: hidden;
          .list-top {
            height: 100%;
          }
          .list-text {
            line-height: 40px;
            cursor: pointer;
            position: absolute;
            bottom: 0px;
            left: 0px;
            width: 100%;
            height: 40px;
            line-height: 40px;
            color: #fff;
            background: rgba(0, 0, 0, 0.3);
            text-align: left;
            padding-left: 5px;
            font-weight: bold;
          }
          .video-poster {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            background: no-repeat 50% / cover;
          }
        }
        // .tj-list:hover {
        //   .video-poster,
        //   .list-text {
        //     display: none;
        //   }
        // }
      }
    }
    .link {
      height: 180px;
      width: 1200px;
      margin: 50px auto;
      display: flex;
      .link-left {
        width: 100%;
        text-align: left;
        background-color: #bde1f9;
        text-align: left;
        position: relative;
        margin-top: 50px;
        .name {
          width: 300px;
          height: 40px;
          position: absolute;
          left: 50%;
          top: -50px;
          transform: translate(-150px);
          color: #707070;
          font-size: 1.8rem;
          text-align: center;
          line-height: 40px;
        }
        .link-img {
          width: 95%;
          margin: 10px 30px 0px 30px;
          overflow: hidden;
          .link-list {
            width: 600px;
            width: 250%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            flex-wrap: wrap;
            .link-detail {
              width: 117px;
              height: 65px;
              margin: 5px;
              background: no-repeat 50% / contain;
              a {
                height: 100%;
                width: 100%;
                display: inline-block;
              }
            }
            .transformLeft {
              transform: translateX(-648px);
              transition: all 800ms ease-in-out;
            }
          }
        }
      }
    }
  }
}
</style>
